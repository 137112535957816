import { useRouter } from "next/router";
import { classNames, userHasClientRole } from "@/utilities";
import { useMemo } from "react";
import Link from "next/link";

const AccountNavbar = ({ userRoles }) => {
    const router = useRouter();
    const { pathname } = router;

    const navigation = useMemo(() => {
        const items = [
            { name: "Account", href: "/company/account" },
            { name: "Company", href: "/company/company" },
        ];

        if (userRoles && userHasClientRole(userRoles)) {
            items.push(
                {
                    name: "Team",
                    href: "/company/team",
                },
                {
                    name: "Notifications",
                    href: "/company/notifications",
                }
            );
        }

        return items;
    }, [userRoles]);

    return (
        <div>
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <nav
                    className="flex lg:space-x-4 border border-t-0 border-l-0 border-r-0 justify-start"
                    aria-label="Global"
                >
                    {navigation.map((item) => (
                        <Link key={item.name} href={item.href}>
                            <a
                                data-test-id={`navigation-${item.name.toLowerCase()}`}
                                className={classNames(
                                    pathname.startsWith(item.href)
                                        ? "text-primary-600 border-primary-500"
                                        : "text-gray-500",
                                    "inline-flex items-center py-4 px-3 text-sm font-medium relative"
                                )}
                                aria-current={item.current ? "page" : undefined}
                            >
                                {item.name}
                                {pathname.startsWith(item.href) && (
                                    <div className="h-1 absolute bottom-0 bg-primary-500 left-0 right-0" />
                                )}
                            </a>
                        </Link>
                    ))}
                </nav>
            </div>
        </div>
    );
};

export default AccountNavbar;
