import { Popover, Transition } from "@headlessui/react";
import { companyNavigation, engineerNavigation } from "@/constants";
import React, { Fragment } from "react";
import { useAuth } from "@/hooks/auth";
import Link from "next/link";
import {
    checkNavItemConditions,
    classNames,
    getHeader,
    userHasClientRole,
    userRole,
} from "@/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import AccountMenu from "@/components/commonlayout/AccountMenu";
import ProfilePicture from "@/components/candidates/ProfilePicture";
import AccountMenuLogo from "@/components/commonlayout/AccountMenuLogo";
import CreditsRemaining from "@/components/commonlayout/CreditsRemaining";
import { useRouter } from "next/router";
import AccountNavbar from "./AccountNavbar";
import Image from "next/image";
import dynamic from "next/dynamic";
import { useGetOrganizationDataQuery } from "@/services/modules/organization";
import { MenuDropdownLayout } from "../MenuDropdownLayout";
import SpinnerLoader from "../SpinnerLoader";

const MinimalFooter = dynamic(() => import("@/components/commonlayout/MinimalFooter"));

const DynamicBodyLayout = dynamic(
    () => import("../../components/layouts/BodyLayout"),
    {
        ssr: false,
        loading: () => <SpinnerLoader />,
    }
);

const MainLayout = ({
    headers,
    heading,
    navTab,
    children,
    backgroundColor,
    showAccountNavbar,
    showBackButton = false,
}) => {
    const { logout } = useAuth();
    const { user } = useAuth({ middleware: "auth" });
    const { userIsCompany, userIsEngineer } = userRole(user);
    useGetOrganizationDataQuery(
        { id: user?.organization?.id },
        { skip: !user }
    );

    let navigation = [];
    let module = "";

    if (userIsCompany) {
        navigation = [
            ...navigation,
            ...companyNavigation.filter(
                ({ conditions }) =>
                    !conditions || checkNavItemConditions(conditions, user)
            ),
        ];
        module = "company";
    }

    if (userIsEngineer) {
        navigation = [...navigation, ...engineerNavigation];
        module = "engineer";
    }

    const homePage =
        navigation[0]?.href ??
        (userIsCompany ? "/company/dashboard" : "/engineer/profile");

    const router = useRouter();
    const { pathname } = router;
    const userRoles = user?.roles;
    const showTeamAndNotifications = userRoles && userHasClientRole(userRoles);
    return (
        <Fragment>
            {getHeader(pathname, headers)}
            <div className="min-h-screen flex flex-col">
                <header className="bg-white shadow header-auth">
                    <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                        <Popover className="flex justify-between h-16">
                            <div className="flex px-2 lg:px-0">
                                <div className="flex-shrink-0 flex items-center">
                                    <Link href={homePage}>
                                        <a
                                            className="flex"
                                            data-test-id="tacnique-logo"
                                            aria-label="tacnique-logo"
                                        >
                                            <Image
                                                src="/logos/tacnique-logo.svg"
                                                alt="Tacnique"
                                                title="Tacnique"
                                                width={114}
                                                height={34}
                                                priority={true}
                                            />
                                        </a>
                                    </Link>
                                </div>
                                <nav
                                    aria-label="Global"
                                    className="hidden lg:ml-6 lg:flex lg:items-center lg:space-x-4"
                                >
                                    {navigation.map((item, index) => (
                                        <React.Fragment key={item.name}>
                                            {item.children ? (
                                                <MenuDropdownLayout
                                                    item={item}
                                                    renderTrigger={() => (
                                                        <span
                                                            key={item.name}
                                                            className={classNames(
                                                                "pb-4.5 px-3 pt-6 font-medium text-sm relative",
                                                                item.name ===
                                                                    navTab
                                                                    ? "text-primary-600"
                                                                    : "text-gray-500"
                                                            )}
                                                            data-test-id={`nav-${item.name
                                                                .replace(
                                                                    " ",
                                                                    "-"
                                                                )
                                                                .toLowerCase()}`}
                                                        >
                                                            <div
                                                                className={classNames(
                                                                    item.name ===
                                                                        navTab
                                                                        ? "mt-0.5"
                                                                        : ""
                                                                )}
                                                            >
                                                                {item.name}
                                                            </div>
                                                            {item.name ===
                                                                navTab && (
                                                                    <div
                                                                        className="border-primary-500 border-b-4 absolute bottom-0 left-0 right-0"
                                                                        style={{
                                                                            top: "-0.5rem",
                                                                        }}
                                                                    />
                                                                )}
                                                        </span>
                                                    )}
                                                    key={index}
                                                />
                                            ) : (
                                                <Link
                                                    key={item.name}
                                                    href={item.href}
                                                >
                                                    <a
                                                        className={classNames(
                                                            item.name === navTab
                                                                ? "text-primary-600 border-primary-500 border-b-4 self-end pb-3.5"
                                                                : "text-gray-500 pb-4",
                                                            "px-3 pt-6 font-medium text-sm"
                                                        )}
                                                        data-test-id={item.name.toLowerCase()}
                                                    >
                                                        {item.name}
                                                    </a>
                                                </Link>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </nav>
                            </div>
                            <div className="flex items-center lg:hidden">
                                {/* Mobile menu button */}
                                <Popover.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    <FontAwesomeIcon
                                        icon={faBars}
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </Popover.Button>
                            </div>
                            <Transition.Root as={Fragment}>
                                <div className="lg:hidden">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="duration-150 ease-out"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="duration-150 ease-in"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Popover.Overlay
                                            className="z-20 fixed inset-0 bg-black bg-opacity-25"
                                            aria-hidden="true"
                                        />
                                    </Transition.Child>

                                    <Transition.Child
                                        as={Fragment}
                                        enter="duration-150 ease-out"
                                        enterFrom="opacity-0 scale-95"
                                        enterTo="opacity-100 scale-100"
                                        leave="duration-150 ease-in"
                                        leaveFrom="opacity-100 scale-100"
                                        leaveTo="opacity-0 scale-95"
                                    >
                                        <Popover.Panel
                                            focus
                                            className="z-30 absolute top-0 right-0 max-w-none w-full p-2 transition transform origin-top"
                                        >
                                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white">
                                                {!userIsEngineer && (
                                                    <div className="pt-3 pb-2">
                                                        <div className="flex items-center justify-between px-5">
                                                            <div className="flex items-center gap-3">
                                                                <AccountMenuLogo
                                                                    user={user}
                                                                />
                                                                {userIsCompany && (
                                                                    <CreditsRemaining
                                                                        user={
                                                                            user
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="-mr-2">
                                                                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                                                                    <span className="sr-only">
                                                                        Close
                                                                        menu
                                                                    </span>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faXmark
                                                                        }
                                                                        className="h-5 w-5"
                                                                        aria-hidden="true"
                                                                    />
                                                                </Popover.Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="pt-4 pb-2">
                                                    <div className="flex justify-between">
                                                        <div className="flex items-center px-5">
                                                            <ProfilePicture
                                                                user={user}
                                                                size={12}
                                                            />
                                                            <div className="ml-3">
                                                                <div className="text-base font-medium text-gray-800">
                                                                    {user?.name}
                                                                </div>
                                                                <div className="text-sm font-medium text-gray-500">
                                                                    {
                                                                        user?.email
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {userIsEngineer && (
                                                            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                                                                <span className="sr-only">
                                                                    Close menu
                                                                </span>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faXmark
                                                                    }
                                                                    className="h-5 w-5"
                                                                    aria-hidden="true"
                                                                />
                                                            </Popover.Button>
                                                        )}
                                                    </div>
                                                    <div className="mt-3 px-2 space-y-1">
                                                        {navigation.map(
                                                            (item) => (
                                                                <React.Fragment
                                                                    key={
                                                                        item.name
                                                                    }
                                                                >
                                                                    {item.children ? (
                                                                        item.children.map(
                                                                            (
                                                                                item
                                                                            ) => (
                                                                                <Link
                                                                                    key={
                                                                                        item.name
                                                                                    }
                                                                                    href={
                                                                                        item.href
                                                                                    }
                                                                                >
                                                                                    <a
                                                                                        className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                                                        data-test-id={`mobile-${item.name.toLowerCase()}-page`}
                                                                                    >
                                                                                        {
                                                                                            item.name
                                                                                        }
                                                                                    </a>
                                                                                </Link>
                                                                            )
                                                                        )
                                                                    ) : (
                                                                        <Link
                                                                            key={
                                                                                item.name
                                                                            }
                                                                            href={
                                                                                item.href
                                                                            }
                                                                        >
                                                                            <a
                                                                                className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                                                data-test-id={`mobile-${item.name.toLowerCase()}-page`}
                                                                            >
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </a>
                                                                        </Link>
                                                                    )}
                                                                </React.Fragment>
                                                            )
                                                        )}
                                                        <Link
                                                            key={"account"}
                                                            href={`/${module}/account`}
                                                        >
                                                            <a
                                                                className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                                data-test-id="mobile-account-page"
                                                            >
                                                                Account
                                                            </a>
                                                        </Link>
                                                        {module ===
                                                            "company" && (
                                                                <>
                                                                    <Link
                                                                        key={
                                                                            "company"
                                                                        }
                                                                        href={`/${module}/company`}
                                                                    >
                                                                        <a
                                                                            className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                                            data-test-id={
                                                                                "company-page"
                                                                            }
                                                                        >
                                                                            Company
                                                                        </a>
                                                                    </Link>
                                                                    {showTeamAndNotifications && (
                                                                        <>
                                                                            <Link
                                                                                key={
                                                                                    "team"
                                                                                }
                                                                                href={`/${module}/team`}
                                                                            >
                                                                                <a
                                                                                    className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                                                    data-test-id={
                                                                                        "team-page"
                                                                                    }
                                                                                >
                                                                                    Team
                                                                                </a>
                                                                            </Link>
                                                                            <Link
                                                                                key={
                                                                                    "notifications"
                                                                                }
                                                                                href={`/${module}/notifications`}
                                                                            >
                                                                                <a
                                                                                    className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                                                    data-test-id={
                                                                                        "notifications-page"
                                                                                    }
                                                                                >
                                                                                    Notifications
                                                                                </a>
                                                                            </Link>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        <button
                                                            key={"logout"}
                                                            className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800 w-full text-left"
                                                            onClick={logout}
                                                        >
                                                            Logout
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Popover.Panel>
                                    </Transition.Child>
                                </div>
                            </Transition.Root>
                            <AccountMenu
                                size="large"
                                module={module}
                                check={true}
                                user={user}
                                logout={logout}
                                userRoles={userRoles}
                            />
                        </Popover>
                    </div>
                </header>
                {showAccountNavbar && <AccountNavbar userRoles={userRoles} />}
                <DynamicBodyLayout
                    backgroundColor={backgroundColor}
                    heading={heading}
                    showBackButton={showBackButton}
                >
                    {children}
                </DynamicBodyLayout>
                <MinimalFooter />
            </div>
        </Fragment>
    );
};

export default MainLayout;
