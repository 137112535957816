import { Popover, Transition } from "@headlessui/react";
import { useRouter } from "next/router";
import { Fragment, useRef } from "react";
import Link from "next/link";
import { classNames } from "@/utilities";

export const MenuDropdownLayout = ({ item, renderTrigger }) => {
    const router = useRouter();
    const buttonRef = useRef(null);
    const timeoutDuration = 200;
    let timeout;
    const closePopover = () => {
        return buttonRef.current?.dispatchEvent(
            new KeyboardEvent("keydown", {
                key: "Escape",
                bubbles: true,
                cancelable: true,
            })
        );
    };

    const onMouseEnter = (open) => {
        clearTimeout(timeout);
        if (open) return;
        return buttonRef.current?.click();
    };

    const onMouseLeave = (open) => {
        if (!open) return;
        timeout = setTimeout(() => closePopover(), timeoutDuration);
    };

    return (
        <Popover className="relative inline-block">
            {({ open }) => (
                <>
                    {" "}
                    <Popover.Button
                        ref={buttonRef}
                        onMouseEnter={onMouseEnter.bind(null, open)}
                        onMouseLeave={onMouseLeave.bind(null, open)}
                        className="inline-flex items-center gap-x-1 font-semibold leading-6 text-gray-900 outline-none"
                    >
                        {renderTrigger(open)}
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute left-[5.43rem] z-10 -mt-0.5 w-[13rem] flex -translate-x-1/2 px-4">
                            <div
                                onMouseEnter={onMouseEnter.bind(null, open)}
                                onMouseLeave={onMouseLeave.bind(null, open)}
                                className="w-40 flex-auto rounded-md bg-white p-2 text-sm mt-1 leading-6 shadow-lg ring-1 ring-gray-600/5 ring-t-0 overflow-hidden"
                            >
                                {item.children.map((item, index) => (
                                    <div
                                        key={index}
                                        className={classNames(
                                            "flex gap-x-6 relative rounded-lg p-2 hover:bg-gray-50 whitespace-nowrap",
                                            item.links.includes(
                                                router.pathname
                                            ) && "bg-gray-50"
                                        )}
                                    >
                                        {item.icon && (
                                            <div>
                                                <div className="rounded-lg p-3 bg-gray-50">
                                                    <item.icon
                                                        className="h-6 w-6 text-gray-40"
                                                        aria-hidden="true"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div>
                                            <Link href={item.href}>
                                                <a
                                                    className={`font-medium ${
                                                        item.links.includes(
                                                            router.pathname
                                                        )
                                                            ? "text-primary-600"
                                                            : "text-gray-500"
                                                    }`}
                                                >
                                                    {item.name}
                                                    <span className="absolute inset-0" />
                                                </a>
                                            </Link>
                                            {item.description && (
                                                <p className="mt-1 text-gray-600">
                                                    {item.description}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );
};
